import { Component, OnInit } from '@angular/core';
import { RemoteService } from '../../providers/remote.service';
import {ModalController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {

  logOutButtonCheck = false;
  constructor(public alertController :AlertController,public remoteService: RemoteService,
    public router: Router) {
    
  }

  ngOnInit() {
    console.clear();
    setTimeout(()=>{
      this.logOutButtonCheck = this.remoteService.user ? this.remoteService.user.firstName ? true : false : false;
      console.clear();
    },5000)
  }

  async logout(){
    var alertMsg = "Are you sure to logout from the current session?"
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      subHeader: alertMsg,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          cssClass: 'secondary',
          handler: () => {
            console.log('logout clicked') 
            this.logOutSuccess()             
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger delete-address',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
    await alert.present();
  }
  
  logOutSuccess(){
    localStorage.setItem('quoteData','')
    localStorage.setItem('bomId','')
    this.remoteService.user = null;
    this.router.navigateByUrl('/session-timeout-model');

    // const alert = await this.alertController.create({
    //   cssClass: 'my-custom-class',
    //   subHeader: '',
    //   message: "You are successfully logged out!",
    //   backdropDismiss: false
    // });
    // await alert.present();
  }
}
